<template lang="pug">
div(:style="{ width, height }" ref="lineChart" class="lineChart")
</template>
<script>
import echarts from 'echarts'
import moment from 'moment'

require('echarts/theme/macarons')
export default {
  name: 'line-chart',
  props: {
    dataSource: {
      type: Array,
      default: () => []
    },
    xKey: {
      type: String,
      default: 'name'
    },
    yKey: {
      type: String,
      default: 'value'
    },
    level: {
      type: String,
      default: 'second'
    },
    legend: {
      type: Array
    },
    lineKeys: {
      type: Array
    },
    xOffset: {
      type: Number,
      default: 0
    },
    width: {
      type: String,
      default: '102%'
    },
    height: {
      type: String,
      default: '350px'
    },
    step: {
      type: Number,
      default: 60
    },
    game: {
      type: String,
      default: 'all'
    },
    operator: {
      type: String,
      default: 'all'
    },
    time: {
      type: String,
      default: ''
    },
    showLegend: {
      type: Boolean,
      default: true
    },
    selectedDateRange: {
      type: Array
    }
  },
  data () {
    return {
      chart: null,
      periods: null
    }
  },
  watch: {
    legend (nv, ov) {
      if (!this.chart) return
      let series = this.legend.map(s => {
        return {
          name: s,
          type: 'line',
          lineStyle: {
            width: 1
          },
          smooth: false,
          symbol: 'none',
          animation: false
        }
      })

      // series = this.seriesWrapper(series)
      this.chart.setOption({
        legend: {
          data: this.legend,
          top: '5%',
          show: this.showLegend
        },
        grid: {
          top: '30%',
          bottom: '10%',
          left: '1%',
          right: '8%',
          containLabel: true
        },
        series
      })
      this.getChartData(true)
    },
    dataSource (nv, ov) {
      if (!this.chart) return
      let resetPeriods = nv.length !== ov.length
      this.getChartData(resetPeriods)
    },
    async game (nv, ov) {
      if (!this.chart) return
      await this.resetChart()
    },
    async operator (nv, ov) {
      if (!this.chart) return
      await this.resetChart()
    },
    async time (nv, ov) {
      if (!this.chart) return
      await this.resetChart()
    },
    async selectedDateRange (nv, ov) {
      if (!this.chart) return
      await this.resetChart()
    }
  },
  async mounted () {
    await this.initChart()
    if (this.chart && this.legend.length) {
      await this.getChartData(true)
    }
  },
  methods: {
    getPeriodName (index, data) {
      switch (this.level) {
        case 'second':
          while (this.dataSource.length < this.step) {
            this.dataSource.push({})
          }
          return `${index + this.xOffset + 1}s`
        case 'minute':
          return data.name
        case 'hour':
          if (this.step > 24) {
            return `${moment(data.name, 'YYYY-MM-DD HH:mm:ss').format('DD HH:00')}`
          }
          return `${moment(data.name, 'YYYY-MM-DD HH:mm:ss').format('HH:00')}`
        case 'day':
          return `${moment(data.name, 'YYYY-MM-DD HH:mm:ss').format('MM/DD')}`
      }
    },
    initPeriods () {
      while (this.periods.length < this.step) {
        const index = this.periods.length
        const data = this.dataSource[index]
        const period = this.getPeriodName(index, data)
        this.periods.push(period)
      }
    },
    async initChart () {
      const chart = echarts.init(this.$refs.lineChart, 'macarons')
      let series = this.legend.map(s => {
        return {
          name: s,
          type: 'line',
          lineStyle: {
            width: 1
          },
          smooth: false,
          symbol: 'none',
          animation: false,
          symbolOffset: ['0%', '0%']
        }
      })

      // series = this.seriesWrapper(series)
      let option = {
        legend: {
          data: this.legend,
          show: this.showLegend,
          top: '5%'
        },
        grid: {
          top: '30%',
          bottom: '10%',
          left: '1%',
          right: '8%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.periods
        },
        yAxis: {
        },
        series
      }

      await chart.setOption(option)

      this.chart = chart
    },
    seriesWrapper (series) {
      return series
    },
    async getChartData (reset = false) {
      let dataObject = {}
      if (reset) this.periods = []
      this.dataSource.forEach((d, index) => {
        if (!d) return
        this.lineKeys.forEach(k => {
          let dk = _.camelCase(k)
          if (!dataObject[k]) dataObject[k] = { data: [] }
          dataObject[k].data.push(d[dk])
        })
        if (reset) this.periods[index] = this.getPeriodName(index, d)
      })

      if (reset) {
        this.periods = _.take(this.periods, this.step)
        const reverseLevel = ['second']
        if (reverseLevel.includes(this.level)) this.periods = _.reverse(this.periods)
      }
      let series = _.values(dataObject)
      this.chart.setOption({
        series,
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.periods
        }
      })
    },
    async resetChart () {
      this.chart.clear()
      this.chart = null
      this.periods = null
      await this.initChart()
    }
  }
}
</script>
